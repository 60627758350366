<template>
  <v-app>
    <v-row v-if="loading">
      <v-col cols="12" >
        <v-skeleton-loader
            v-bind="attrs"
            class="mx-auto"
            type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <div class="row" v-else>
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>{{ title }} exam</h4>
              </div>
              <div class="breadcrumb-right">
                <v-btn
                    :loading="loading"
                    @click.prevent="saveProduct()"
                    x-large
                    dark
                >Save</v-btn>
              </div>
            </div>
          </div>

          <div class="card-body">
            <v-tabs vertical v-model="product_tab">
              <v-tab href="#tab-1">
                 General
              </v-tab>
              <v-tab v-if="product.type != 'ameb_award'" href="#tab-2">
                Category, intruments & syllabus
              </v-tab>
              <v-tab href="#tab-3" v-if="product.type != 'ameb_award'">
                Certificate and reports
              </v-tab>
              <v-tab v-if="product.type == 'practical_exam'" href="#tab-4">
                Group exam
              </v-tab>
              <v-tab v-if="product.type == 'practical_exam'" href="#tab-5">
                Co-requisites
              </v-tab>
              <v-tab v-if="product.type == 'practical_exam'" href="#tab-6">
                Repertoire playlist
              </v-tab>
              <v-tab v-if="product.type == 'practical_exam'" href="#tab-7">
                Collaborative instruments
              </v-tab>
              <v-tab href="#tab-8">
                Others
              </v-tab>
              <v-tab v-if="software_external_keys_flag" href="#tab-9">
                Rising Software config
              </v-tab>
              <v-tab href="#tab-10">
                Pricing
              </v-tab>
              <v-tab v-if="productId" href="#tab-11">
                Images
              </v-tab>
              <!--General information -->
              <v-tab-item class="p-4" :value="'tab-1'">
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h4>General information</h4><hr/>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-select
                          :items="types"
                          item-text="text"
                          item-value="value"
                          @change="handleProductTypeChange()"
                          v-model="product.type"
                          outlined
                          :error="$v.product.type.$error"
                          dense
                      >
                        <template v-slot:label>
                          Product type <span class="text-danger">*</span>
                        </template>
                      </v-select>
                      <div class="text-danger" v-if="$v.product.type.$error">
                        This information is required
                      </div>
                      <span class="text-danger" v-if="errors.type"
                      >*{{ errors.type[0] }}</span
                      >
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                          v-model="product.name"
                          outlined
                          :error="$v.product.name.$error"
                          dense
                      >
                        <template v-slot:label>
                          Product name <span class="text-danger">*</span>
                        </template>
                      </v-text-field>
                      <div class="text-danger" v-if="$v.product.name.$error">
                        This information is required
                      </div>
                      <span class="text-danger" v-if="errors.name"
                      >*{{ errors.name[0] }}</span
                      >
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                          placeholder="Subject code"
                          v-model="product.subject_code"
                          outlined
                          :error="$v.product.subject_code.$error"
                          dense
                      >
                        <template v-slot:label>
                         Subject code <span class="text-danger">*</span>
                        </template>
                      </v-text-field>
                      <div class="text-danger" v-if="$v.product.subject_code.$error">
                        This information is required
                      </div>
                      <span class="text-danger" v-if="errors.subject_code"
                      >*{{ errors.subject_code[0] }}</span
                      >
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                          label="Exam duration"
                          v-model="product.exam_duration"
                          type="number"
                          outlined
                          dense
                      >
                        <template v-slot:label>
                          Exam duration
                        </template>
                      </v-text-field>
                      <div class="text-danger" v-if="$v.product.exam_duration.$error">
                        This information is required
                      </div>
                      <span class="text-danger" v-if="errors.exam_duration"
                      >{{ errors.exam_duration[0] }}</span
                      >
                    </v-col>
                    <v-col cols="12" md="12" v-if="currentUser.access_type == 'federal'">
                      <v-select
                          label="Applicable Scores"
                          item-text="name"
                          item-value="id"
                          :items="scores"
                          v-model="product.applicable_scores"
                          :error="$v.product.applicable_scores.$error"
                          multiple
                          small-chips
                          deletable-chips
                          outlined
                          dense
                      >
                        <template v-slot:prepend-item>
                          <v-list-item
                              ripple
                              @click="allSelectScores"
                          >
                            <v-list-item-action>
                              <v-icon :color="product.applicable_scores > 0 ? 'indigo darken-4' : ''">
                                {{ icon }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                Select all
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-1"></v-divider>
                        </template>
                      </v-select>
                      <div
                          class="text-danger"
                          v-if="$v.product.applicable_scores.$error"
                      >
                        This information is required
                      </div>
                      <span class="text-danger" v-if="errors.applicable_scores"
                      >*{{ errors.applicable_scores[0] }}</span
                      >
                    </v-col>
                    <v-col cols="12" md="6">
                      <label class=""> Long description</label>
                      <ckeditor
                          :config="longDescriptionEditorConfig"
                          v-model="product.long_description"
                          :error="$v.product.long_description.$error"
                      >
                      </ckeditor>

                      <!-- <v-textarea
                        outlined
                        dense
                        v-model="product.long_description"
                        :error="$v.product.long_description.$error"
                      >
                      </v-textarea> -->
                      <span class="text-danger" v-if="errors.long_description"
                      >{{ errors.long_description[0] }}</span
                      >
                      <span
                          class="text-danger"
                          v-if="$v.product.long_description.$error"
                      >This information is required</span
                      >
                    </v-col>
                    <v-col cols="12" md="6">
                      <label class="text-body"> Short description</label>
                      <ckeditor
                          :config="shortDescriptionEditorConfig"
                          :error="$v.product.short_description.$error"
                          v-model="product.short_description"
                      >
                      </ckeditor>
                      <!-- <v-textarea
                        outlined
                        dense
                        v-model="product.short_description"
                        :error="$v.product.short_description.$error"
                      >
                      </v-textarea> -->
                      <span class="text-danger" v-if="errors.short_description"
                      >{{ errors.short_description[0] }}</span
                      >
                      <span
                          class="text-danger"
                          v-if="$v.product.short_description.$error"
                      >This information is required</span
                      >
                    </v-col>

                    <v-col cols="12" md="6" v-if="product.type=='online_course'">
                      <label class="text-body"> Candidate welcome message</label>
                      <ckeditor
                          :config="candidateWelcomeMessageEditorConfig"
                          :error="$v.product.candidate_welcome_message.$error"
                          v-model="product.candidate_welcome_message"
                      >
                      </ckeditor>
                      <!-- <v-textarea
                        outlined
                        dense
                        v-model="product.short_description"
                        :error="$v.product.short_description.$error"
                      >
                      </v-textarea> -->
                      <span class="text-danger" v-if="errors.short_description"
                      >{{ errors.short_description[0] }}</span
                      >
                      <span
                          class="text-danger"
                          v-if="$v.product.short_description.$error"
                      >This information is required</span
                      >
                    </v-col>
                  </v-row>
              </v-tab-item>
              <!-- Category, instrument and syllabus -->
              <v-tab-item class="p-4" :value="'tab-2'">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <h4>Category, instrument & syllabus</h4><hr/>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-select
                        label="Category"
                        v-model="product.category_id"
                        :items="categories"
                        @change="changeCategory"
                        outlined
                        :error="$v.product.category_id.$error"
                        item-text="name"
                        item-value="id"
                        dense
                    >
                    </v-select>
                    <span class="text-danger" v-if="errors.category_id">{{
                        errors.category_id[0]
                      }}</span>
                    <div class="text-danger" v-if="$v.product.category_id.$error">
                      This information is required
                    </div>
                  </v-col>
                  <v-col v-if="instrument_flag" cols="12" sm="12" md="12">
                    <v-select
                        label="Instrument"
                        v-model="product.instrument_id"
                        :items="instruments"
                        outlined
                        @change="changeInstrument"
                        item-text="name"
                        :loading="loading_instrument"
                        :error="$v.product.instrument_id.$error"
                        item-value="id"
                        dense
                    >
                    </v-select>
                    <div class="text-danger" v-if="$v.product.instrument_id.$error">
                      This information is required
                    </div>
                    <span class="text-danger" v-if="errors.instrument_id">{{
                        errors.instrument_id[0]
                      }}</span>
                  </v-col>
                  <v-col v-if="syllabus_flag" cols="12" sm="12" md="12">
                    <v-select
                        label="Syllabus"
                        v-model="product.syllabus_id"
                        :items="syllabuses"
                        outlined @change="changeSyllabus"
                        :loading="loading_syllabus"
                        item-text="name"
                        item-value="id"
                        :error="$v.product.syllabus_id.$error"
                        dense
                    >
                    </v-select>
                    <div class="text-danger" v-if="$v.product.syllabus_id.$error">
                      This information is required
                    </div>
                    <span class="text-danger" v-if="errors.syllabus_id">{{
                        errors.syllabus_id[0]
                      }}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-select
                        label="Grade"
                        v-model="product.grade_id"
                        :items="grades"
                        outlined
                        item-text="name"
                        item-value="id"
                        dense
                    >
                    </v-select>
                    <span class="text-danger" v-if="errors.grade_id">{{
                        errors.grade_id[0]
                      }}</span>
                    <div class="text-danger" v-if="$v.product.grade_id.$error">
                      This information is required
                    </div>
                  </v-col>
                </v-row>
                <v-row v-if="practical_exam_fields_flag">
                  <v-col cols="12" sm="12" md="12" v-if="this.exam_option_flag ">
                    <h4>Exam Options</h4>
                  </v-col>
                  <v-col cols="12" md="12" v-if="exam_type_flag">
                    <v-select @change="changeExamType"
                              :items="exam_types"
                              item-text="name"
                              item-value="id"
                              outlined
                              v-model="product.exam_type_id"
                              label="Exam Type"
                              :error="$v.product.exam_type_id.$error"
                              dense
                    >
                    </v-select>
                    <div class="text-danger" v-if="$v.product.exam_type_id.$error">
                      This information is required
                    </div>
                    <span class="text-danger" v-if="errors.exam_type_id"
                    >*{{ errors.exam_type_id[0] }}</span
                    >
                  </v-col>
                  <v-col cols="12" md="12" v-if="exam_option_flag">
                    <v-select
                        :items="exam_options"
                        item-text="name"
                        item-value="id"
                        v-model="product.exam_option_id"
                        outlined
                        :error="$v.product.exam_option_id.$error"
                        label="Exam Option"
                        dense
                    >
                    </v-select>
                    <div
                        class="text-danger"
                        v-if="$v.product.exam_option_id.$error"
                    >
                      This information is required
                    </div>
                    <span class="text-danger" v-if="errors.exam_option_id"
                    >*{{ errors.exam_option_id[0] }}</span
                    >
                  </v-col>
                </v-row>

              </v-tab-item>
              <!--Certificate & Report-->
              <v-tab-item class="p-4" :value="'tab-3'">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <h4>Certificates and reports</h4><hr/>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-select
                        v-model="product.marking_type"
                        :items="marking_types"
                        outlined
                        :error="$v.product.marking_type.$error"
                        item-text="text"
                        item-value="value"
                        dense
                    >
                      <template v-slot:label>
                        Marking type <span class="text-danger">*</span>
                      </template>
                    </v-select>
                    <div class="text-danger" v-if="$v.product.marking_type.$error">
                      This information is required
                    </div>
                    <span class="text-danger" v-if="errors.marking_type">{{
                        errors.marking_type[0]
                      }}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-select
                        v-model="product.certificate_type"
                        :items="certificate_types"
                        outlined
                        :error="$v.product.certificate_type.$error"
                        item-text="text"
                        item-value="value"
                        dense
                    >
                      <template v-slot:label>
                        Certificate Type <span class="text-danger">*</span>
                      </template>
                    </v-select>
                    <div
                        class="text-danger"
                        v-if="$v.product.certificate_type.$error"
                    >
                      This information is required
                    </div>
                    <span class="text-danger" v-if="errors.certificate_type">{{
                        errors.certificate_type[0]
                      }}</span>
                  </v-col>
                  </v-row>
                  <v-row v-if="practical_exam_fields_flag">
                  <v-col cols="12" md="6">
                    This is a P-Plate Piano exam ?
                    <v-switch
                        v-model="product.is_pplatepiano"
                        :label="product.is_pplatepiano ? 'Yes':'No'"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" md="6">
                    This is a diploma exam ?
                    <v-switch
                        v-model="product.is_diploma"
                        :label="product.is_diploma ? 'Yes':'No'"
                    ></v-switch>
                  </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                          placeholder="Certificate print name"
                          v-model="product.certificate_print_name"
                          outlined dense>
                        <template v-slot:label>
                          Certificate print name
                        </template>
                      </v-text-field>
                    </v-col>
                  <v-col cols="12" md="12">
                    <v-select
                        outlined
                        label="Certificate template"
                        v-model="product.certificate_template_type"
                        :items="certificate_template_types"
                        item-text="name"
                        item-value="value"
                        dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <h4>Practical exam report configuration</h4>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                        label="Fields on Report"

                        item-value="value" item-text="text"
                        :items="practical_result_report_options"
                        v-model="practical_result_report_value"
                        multiple
                        small-chips
                        deletable-chips
                        outlined
                        dense
                    >
                      <template v-slot:prepend-item>
                        <v-list-item
                            ripple
                            @click="allAcceptedValue"
                        >
                          <v-list-item-action>
                            <v-icon :color="practical_result_report_value > 0 ? 'indigo darken-4' : ''">
                              {{ iconAcceptValue }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Select all
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-1"></v-divider>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-select item-value="value" item-text="text"
                              :items="piece_naming_standard"
                              v-model="product.piece_naming_standard"
                              dense outlined>
                      <template v-slot:label>
                        Piece naming standard
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select item-value="value" item-text="text" @change="changeMarkType"
                              :items="mark_types"
                              v-model="product.mark_types"
                              dense outlined>
                      <template v-slot:label>
                        Marking standard
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="6" v-if="product.mark_types == 'percentage' || product.mark_types == 'marks'">
                    <v-text-field v-model="product.certificate_print_criteria"
                                  dense outlined type="number">
                      <template v-slot:label>
                        {{product.mark_types == 'percentage' ? 'Percentage' : 'Mark' }}
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" v-if="product.mark_types == 'grade'">
                    <v-autocomplete outlined v-model="product.certificate_print_criteria" :items="mark_options" dense multiple small-chips deletable-chips>
                      <template v-slot:label> Mark Obtained </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6" v-if="product.mark_types == 'title'">
                    <v-select outlined v-model="product.certificate_print_criteria" :items="mark_options" dense multiple small-chips deletable-chips>
                      <template v-slot:label> Mark Title </template>
                    </v-select>
                  </v-col>
                  <v-col cols="6" v-if="product.mark_types == 'rockschool_professional_diplomas'">
                    <v-select outlined v-model="product.certificate_print_criteria" :items="mark_options" dense multiple small-chips deletable-chips>
                      <template v-slot:label>Rockschool Professional Diplomas </template>
                    </v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="product.general_remarks_naming_standard" dense outlined>
                      <template v-slot:label>
                        Different name for 'General Remarks' (if any)
                      </template>
                    </v-text-field>
                  </v-col>

                </v-row>
              </v-tab-item>
              <!--group exam-->
              <v-tab-item class="p-4" v-if="practical_exam_fields_flag" :value="'tab-4'">
                <!-- group exam section -->
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <h4>Group exam</h4><hr/>
                  </v-col>
                  <v-col cols="12" md="6">
                    Make this exam available for groups enrolment
                    <v-switch
                        @change="handleGroupExam"
                        v-model="product.is_group_exam"
                        :label="product.is_group_exam ? 'Yes' : 'No'"
                        :error="$v.product.is_group_exam.$error"
                    ></v-switch>
                    <div class="text-danger" v-if="$v.product.is_group_exam.$error">
                      This information is required
                    </div>
                  </v-col>

                  <v-col cols="12" md="6" v-if="group_exam_flag">
                   Upload report as
                    <v-switch
                        v-model="product.is_group_report"
                        :label="product.is_group_report ? 'candidate':'group'"
                        :error="$v.product.is_group_report.$error"
                    ></v-switch>
                    <div
                        class="text-danger"
                        v-if="$v.product.is_group_report.$error"
                    >
                      This information is required
                    </div>
                  </v-col>

                  <v-col v-if="group_exam_flag" cols="12" md="4">
                    <v-text-field
                        v-model="product.group_min_candidates"
                        type="number"
                        :error="$v.product.group_min_candidates.$error"
                        outlined
                        dense
                    >
                      <template v-slot:label>
                         Minimum candidates <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                    <div
                        class="text-danger"
                        v-if="$v.product.group_min_candidates.$error"
                    >
                      This information is required
                    </div>
                  </v-col>

                  <v-col v-if="group_exam_flag" cols="12" md="4">
                    <v-text-field
                        v-model="product.group_max_candidates"
                        type="number"
                        outlined
                        :error="$v.product.group_max_candidates.$error"
                        dense
                    >
                      <template v-slot:label>
                        Maximum candidates <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                    <span class="text-danger" v-if="errors.group_max_candidates"
                    >{{ errors.group_max_candidates[0] }}</span
                    >
                  </v-col>

                  <v-col v-if="group_exam_flag" cols="12" sm="12" md="4">
                    <v-select
                        v-model="product.group_certificate_type"
                        :items="group_certificate_types"
                        outlined
                        :error="$v.product.group_certificate_type.$error"
                        item-text="text"
                        item-value="value"
                        dense
                    >
                      <template v-slot:label>
                        Certificate type <span class="text-danger">*</span>
                      </template>
                    </v-select>
                    <div
                        class="text-danger"
                        v-if="$v.product.group_certificate_type.$error"
                    >
                      This information is required
                    </div>
                    <span
                        class="text-danger"
                        v-if="errors.group_certificate_type"
                    >{{ errors.group_certificate_type[0] }}</span
                    >
                  </v-col>
                  <v-col v-if="group_exam_flag" cols="12" sm="12" md="4">
                    <v-text-field type="number" outlined :error="$v.product.group_price_per_additional_candidates.$error" v-model="product.group_price_per_additional_candidates"
                                  dense label="Group price per additional candidates">
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- group exam section ends -->
              </v-tab-item>
              <!--Co-requisites-->
              <v-tab-item class="p-4" v-if="product.type == 'practical_exam'" :value="'tab-5'">
                <v-row >
                  <v-col cols="12" sm="12" md="12">
                    <h4>Co-requisites</h4><hr/>
                  </v-col>
                  <v-col cols="12" md="2">
                    <label>Enable co-requisites for this exam?</label>
                    <v-switch
                        :label="product.has_corequisites_requirement ? 'Yes' : 'No'"
                        v-model="product.has_corequisites_requirement"
                    >
                    </v-switch>
                  </v-col>
                  <v-col cols="12" md="8" v-if="product.has_corequisites_requirement">
                    <div class="mb-3">
                      <v-chip class="mr-3" small close @click:close="removeCorequisitesProduct(index)" v-for="(item, index) in corequisites_products" :key="index">{{item.name}}</v-chip>
                    </div>
                    <v-autocomplete
                        label="Co-Requisites Products"
                        @change="setCorequisitesProduct"
                        :search-input.sync="productSearch"
                        item-text="name"
                        item-value="id"
                        :items="products"
                        v-model="selectCorequisitesProduct"
                        return-object
                        hide-selected
                        outlined
                        dense
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-tab-item>
              <!--Repertoire playlist-->
              <v-tab-item class="p-4" v-if="practical_exam_fields_flag" :value="'tab-6'">
                <!-- playlist section -->
                <v-row  class="my-4">
                  <v-col cols="12" sm="12" md="12">
                    <h4>Repertoire submission</h4><hr/>
                  </v-col>
                  <v-col cols="12" md="3">
                    Enable repertoire submission for enroller/candidate?
                    <v-switch
                        @change="handleRequirePlaylist"
                        v-model="product.requires_playlist"
                        :label="product.requires_playlist ? 'Yes' : 'No'"
                    ></v-switch>
                    <div
                        class="text-danger"
                        v-if="$v.product.requires_playlist.$error"
                    >
                      This information is required
                    </div>
                    <span
                        class="text-danger"
                        v-if="$v.product.requires_playlist.$error"
                    >This information is required</span
                    >
                  </v-col>

                  <v-col cols="12" md="3">
                    Send repertoire reminder email
                    <v-switch
                        v-model="product.send_repertoire_reminder_email"
                        :label="product.send_repertoire_reminder_email ? 'Yes' : 'No'"
                    ></v-switch>
                    <div
                        class="text-danger"
                        v-if="$v.product.send_repertoire_reminder_email.$error"
                    >
                      This information is required
                    </div>
                    <span
                        class="text-danger"
                        v-if="$v.product.send_repertoire_reminder_email.$error"
                    >This information is required</span
                    >
                  </v-col>

                  <v-col cols="12" md="3" v-if="product.send_repertoire_reminder_email">
                    <v-text-field
                      outlined
                      dense
                      min="1"
                      v-model="product.no_of_day_for_reminder"
                      type="number"
                    >
                      <template
                        v-slot:label
                      >
                      Number of day for reminder
                      <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                    <div
                        class="text-danger"
                        v-if="$v.product.no_of_day_for_reminder.$error"
                    >
                      This information is required
                    </div>
                  </v-col>

                  <v-col v-if="playlist_type_flag" cols="12" md="12">
                    <v-select
                        outlined
                        dense
                        :items="playlist_types"
                        item-text="name"
                        item-value="value"
                        :error="$v.product.playlist_type.$error"
                        v-model="product.playlist_type"
                    >
                      <template v-slot:label>
                        Submission method <span class="text-danger">*</span>
                      </template>
                    </v-select>
                    <div
                        class="text-danger"
                        v-if="$v.product.playlist_type.$error"
                    >
                      This information is required
                    </div>
                    <span
                        class="text-danger"
                        v-if="errors.playlist_type"
                    >{{ errors.playlist_type[0] }}</span
                    >
                  </v-col>

                  <v-col v-if="requires_aural_sight_reading_flag" cols="12" md="4">
                    Does this submission requires aural sight reading?
                    <v-switch
                        v-model="product.requires_aural_sight_reading"
                        :label="product.requires_aural_sight_reading ? 'Yes' : 'No' "
                    ></v-switch>
                    <div
                        class="text-danger"
                        v-if="$v.product.requires_aural_sight_reading.$error"
                    >
                      This information is required
                    </div>
                    <span
                        class="text-danger"
                        v-if="$v.product.requires_aural_sight_reading.$error"
                    >This information is required</span
                    >
                  </v-col>
                </v-row>
                <!-- playlist section ends -->
              </v-tab-item>
              <!--Collaborative Instruments-->
              <v-tab-item class="p-4" v-if="practical_exam_fields_flag" :value="'tab-7'">
                <!-- collaborative instrument section -->
                <v-row  class="my-4">
                  <v-col cols="12" sm="12" md="12">
                    <h4>Collaborative instruments</h4><hr/>
                  </v-col>
                  <v-col cols="12">
                    <h4>Enable collaborative instrument selection on enrolment form?</h4>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-switch
                        v-model="product.requires_collaborative_instrument"
                        :label="product.requires_collaborative_instrument ? 'Yes' : 'No'"
                        @change="handleRequireCollaborativeInstrument"
                    ></v-switch>
                    <span
                        class="text-danger"
                        v-if="$v.product.requires_collaborative_instrument.$error"
                    >This information is required</span
                    >
                  </v-col>

                  <v-col cols="12" md="12" v-if="collaborative_instrument_flag">
                    <v-select
                        v-model="product.collaborative_instrument_category_id"
                        outlined :items="collaborative_instrument_categories" item-text="title" item-value="id"
                        :error="$v.product.collaborative_instrument_category_id.$error"
                        dense
                    >
                      <template v-slot:label>
                        Collaborative instruments selection category <span class="text-danger">*</span>
                      </template>
                    </v-select>
                    <div
                        class="text-danger"
                        v-if="$v.product.collaborative_instrument_category_id.$error"
                    >
                      This information is required
                    </div>
                    <span
                        class="text-danger"
                        v-if="errors.collaborative_instrument_category_id"
                    >*{{ errors.collaborative_instrument_category_id[0] }}</span
                    >
                  </v-col>

                </v-row>
                <!-- colaborative instrument section ends -->
              </v-tab-item>
              <!--Others-->
              <v-tab-item class="p-4" :value="'tab-8'">
                <v-col cols="12" sm="12" md="12">
                  <h4>Other options</h4><hr/>
                </v-col>

                <v-row v-if="practical_exam_fields_flag">
                  <v-col cols="12" md="4" v-if="product.type == 'practical_exam'">
                    Right/left hand selection
                    <v-switch
                        v-model="product.requires_hand_selection"
                        :label="product.requires_hand_selection ? 'Enable' : 'Disable'"
                    ></v-switch>
                    <div
                        class="text-danger"
                        v-if="$v.product.requires_hand_selection.$error"
                    >
                      This information is required
                    </div>
                    <span
                        class="text-danger"
                        v-if="$v.product.requires_hand_selection.$error"
                    >This information is required</span
                    >
                  </v-col>
                  <v-col cols="12" md="4" v-if="product.type == 'practical_exam'">
                    Guitar selection
                    <v-switch
                        v-model="product.requires_guitar_selection"
                        :label="product.requires_guitar_selection ? 'Enable' : 'Disable'"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" md="4" v-if="product.type == 'practical_exam'">
                     Drum selection
                    <v-switch
                        v-model="product.requires_drum_selection"
                        :label="product.requires_drum_selection ? 'Enable' : 'Disable'"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" md="4" v-if="product.type == 'practical_exam'">
                    Recorded accompaniment
                    <v-switch
                        v-model="product.require_recorded_accompaniment"
                        :label="product.require_recorded_accompaniment ? 'Enable' : 'Disable'"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" md="4" v-if="product.type == 'practical_exam'">
                    Additional option titles
                    <v-switch
                        v-model="product.requires_additional_option_title"
                        :label="product.requires_additional_option_title ? 'Enable' : 'Disable'"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" md="6" v-if="product.requires_additional_option_title && product.type == 'practical_exam'">
                    Additional option value
                    <v-select dense outlined v-model="product.additional_option_title_value" :items="additional_option_title_value" item-value="value" item-text="text">

                    </v-select>
                  </v-col>
                  <v-col cols="12" md="4" v-if="product.type == 'practical_exam'">
                    Collect invigilator information for candidate?
                    <v-switch
                        v-model="product.has_external_invigilator"
                        :label="product.has_external_invigilator ? 'Yes, collect this information' : 'No'"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" md="4" v-if="product.type == 'practical_exam'">
                    Add second page repertoire submission information on enroler notification pdf?
                    <v-switch
                        v-model="product.show_account_holder_second_page_notification"
                        :label="product.show_account_holder_second_page_notification ? 'Yes':'No'"
                    ></v-switch>
<!--                    <div class="text-danger" v-if="$v.product.show_account_holder_second_page_notification.$error">-->
<!--                      This information is required-->
<!--                    </div>-->
                  </v-col>
                  <v-col cols="12" md="4" v-if="product.type == 'practical_exam'">
                    Require teaching grade
                    <v-switch
                        v-model="product.require_teaching_grade"
                        :label="product.require_teaching_grade ? 'Yes':'No'"
                    ></v-switch>
                  </v-col>

                </v-row>
                <v-row>

                  <v-col cols="12" md="4" v-if="currentUser.access_type != 'score'">
                    <span>Is administrated by federal</span>
                    <v-switch
                        :label="product.is_administrated_by_federal ? 'Yes' : 'No'"
                        v-model="product.is_administrated_by_federal"
                        outlined
                        dense
                    ></v-switch>
                  </v-col>

                  <v-col cols="12" md="4">
                    Exam visible to enroler
                    <v-switch
                        v-model="product.is_enroler_active"
                        :label="product.is_enroler_active ? 'Yes':'No'"
                        :error="$v.product.is_enroler_active.$error"
                    ></v-switch>

                  </v-col>
                  <v-col cols="12" md="4">
                    Exam visible to admin
                    <v-switch
                        v-model="product.is_admin_active"
                        :label="product.is_admin_active ? 'Yes':'No'"></v-switch>
                  </v-col>

                  <v-col cols="12" md="4">
                    Exam status
                    <v-switch
                        v-model="product.is_active"
                        :label="product.is_active ? 'Active':'Inactive'"
                        :error="$v.product.is_active.$error"
                    ></v-switch>
                    <div class="text-danger" v-if="$v.product.is_active.$error">
                      This information is required
                    </div>
                    <span class="text-danger" v-if="$v.product.is_active.$error"
                    >This information is required</span
                    >
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-select
                      outlined
                      dense
                      v-model="product.marking_score_type"
                      :items="markingScoreTypes"
                      item-text="name"
                      item-value="value"
                      label="Marking score type"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" v-if="product.type == 'ameb_award'">
                    <v-text-field
                      outlined 
                      dense
                      type="number"
                      :error="$v.product.no_of_performances.$error"
                      v-model="product.no_of_performances"
                    >
                      <template
                        v-slot:label
                      >
                        No. of performances <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                    <span class="text-danger" v-if="$v.product.no_of_performances.$error"
                    >This information is required</span>
                  </v-col>



                  <v-col cols="12" md="6" v-if="product.type == 'ameb_award'">
                    <v-text-field
                      outlined 
                      dense
                      type="number"
                      :error="$v.product.no_of_contributions.$error"
                      v-model="product.no_of_contributions"
                    >
                      <template
                        v-slot:label
                      >
                      No. of contributions <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                    <span class="text-danger" v-if="$v.product.no_of_contributions.$error"
                    >This information is required</span>
                  </v-col>

                  <v-col cols="12" md="6" v-if="product.type == 'ameb_award'">
                    <v-text-field
                      outlined 
                      dense
                      type="number"
                      :error="$v.product.min_required_leadership_contributions.$error"
                      v-model="product.min_required_leadership_contributions"
                    >
                      <template
                        v-slot:label
                      >
                      Minimum required leadership contribution <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                    <span class="text-danger" v-if="$v.product.min_required_leadership_contributions.$error"
                      >This information is required</span>
                  </v-col>

                  <v-col cols="12" md="6" v-if="product.type == 'ameb_award'">
                    <v-text-field
                      outlined 
                      dense
                      type="number"
                      :error="$v.product.min_required_substantial_contributions.$error"
                      v-model="product.min_required_substantial_contributions"
                    >
                      <template
                        v-slot:label
                      >
                      Minimum required substantial contribution <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                    <span class="text-danger" v-if="$v.product.min_required_substantial_contributions.$error"
                      >This information is required</span>
                  </v-col>


                  <v-col cols="12" md="6" v-if="product.type == 'ameb_award'">
                    Has substantial contribution
                    <v-switch
                        v-model="product.has_substantial_contribution"
                        :label="product.has_substantial_contribution ? 'Yes':'No'"
                        :error="$v.product.is_active.$error"
                    ></v-switch>
                  </v-col>

                  </v-col>
                </v-row>

              </v-tab-item>
              <!--Rising Software keys-->
              <v-tab-item class="p-4" v-if="software_external_keys_flag" :value="'tab-9'">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <h4>Rising Software External Keys</h4><hr/>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                        label="Official exam external key"
                        v-model="product.external_key_official"
                        outlined
                        :error="$v.product.external_key_official.$error"
                        dense
                    >
                    </v-text-field>
                    <div
                        class="text-danger"
                        v-if="$v.product.external_key_official.$error"
                    >
                      This information is required
                    </div>
                    <span class="text-danger" v-if="errors.external_key_official"
                    >*{{ errors.external_key_official[0] }}</span
                    >
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                        label="Practice exam external key"
                        v-model="product.external_key_practice"
                        outlined
                        :error="$v.product.external_key_practice.$error"
                        dense
                    >
                    </v-text-field>
                    <div
                        class="text-danger"
                        v-if="$v.product.external_key_practice.$error"
                    >
                      This information is required
                    </div>
                    <span class="text-danger" v-if="errors.external_key_practice"
                    >*{{ errors.external_key_practice[0] }}</span
                    >
                  </v-col>
                </v-row>
                <v-row v-if="product.type=='online_exam'">
                  <v-col cols="12" sm="12" md="12">
                    <h4>Select platform of software to be used with this exam</h4>
                  </v-col>
                  <v-col  cols="12" sm="12" md="12">
                    <v-select
                        v-model="product.online_exam_platform"
                        :items="platforms"
                        outlined
                        item-text="text"
                        item-value="value"
                        dense
                    >
                      <template v-slot:label>
                        Software platform to use<span class="text-danger">*</span>
                      </template>
                    </v-select>

                  </v-col>
                </v-row>
              </v-tab-item>
              <!--Pricing-->
              <v-tab-item class="p-4" :value="'tab-10'">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <h4>Pricing </h4><hr/>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                        label="Default Price"
                        v-model="product.default_price"
                        type="number"
                        outlined
                        :error="$v.product.default_price.$error"
                        dense
                    >
                    </v-text-field>
                    <div class="text-danger" v-if="$v.product.default_price.$error">
                      This information is required
                    </div>
                    <span class="text-danger" v-if="errors.default_price"
                    >*{{ errors.default_price[0] }}</span
                    >
                  </v-col>
                  <v-col cols="12" md="12">
                    Make this exam available for free
                    <v-switch
                        v-model="product.is_free"
                        :label="product.is_free ? 'Yes':'No'"
                        :error="$v.product.is_free.$error"
                    ></v-switch>
                    <div class="text-danger" v-if="$v.product.is_free.$error">
                      This information is required
                    </div>
                    <span class="text-danger" v-if="$v.product.is_free.$error"
                    >This information is required</span
                    >
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    Special pricing
                    <v-switch
                        v-model="product.on_special"
                        :label="product.on_special ? 'Yes' : 'No' "
                        :error="$v.product.on_special.$error"
                        @change="handleOnSpecial"
                    ></v-switch>
                    <span class="text-danger" v-if="errors.on_special"
                    >{{ errors.on_special[0] }}</span
                    >
                    <span class="text-danger" v-if="$v.product.on_special.$error"
                    >This information is required</span
                    >
                  </v-col>
                  <v-col cols="12" md="3" v-if="on_special_flag">
                    <v-text-field
                        label="Special Price *"
                        v-model="product.on_special_price"
                        type="number"
                        outlined
                        :error="$v.product.on_special_price.$error"
                        dense
                    >
                      <template v-slot:label>
                        Special Price <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                    <div
                        class="text-danger"
                        v-if="$v.product.on_special_price.$error"
                    >
                      This information is required
                    </div>
                    <span class="text-danger" v-if="errors.on_special_price"
                    >*{{ errors.on_special_price[0] }}</span
                    >
                  </v-col>
                  <v-col cols="12" md="3" v-if="on_special_flag">
                    <v-text-field
                        v-model="product.special_pricing_text"
                        outlined
                        :error="$v.product.special_pricing_text.$error"
                        dense
                    >
                      <template v-slot:label>
                        Special Pricing Text <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                    <div
                        class="text-danger"
                        v-if="$v.product.special_pricing_text.$error"
                    >
                      This information is required
                    </div>
                    <span class="text-danger" v-if="errors.special_pricing_text"
                    >*{{ errors.special_pricing_text[0] }}</span
                    >
                  </v-col>
                  <v-col cols="12" md="3" v-if="on_special_flag">
                    <v-menu
                        ref="menu"
                        v-model="on_special_start_date"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="product.on_special_start_date"
                            label="Special View from date *"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            :error="$v.product.on_special_start_date.$error"
                            v-on="on"
                            dense
                            outlined
                        >
                          <template v-slot:label>
                            Special view from date <span class="text-danger">*</span>
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                          no-title
                          :error="$v.product.on_special_start_date.$error"
                          v-model="product.on_special_start_date"
                          :active-picker.sync="activePicker"
                          :min="currentDate"
                          @change="save"
                      ></v-date-picker>
                    </v-menu>
                    <div
                        class="text-danger"
                        v-if="$v.product.on_special_start_date.$error"
                    >
                      This information is required
                    </div>
                    <span class="text-danger" v-if="errors.on_special_start_date"
                    >*{{ errors.on_special_start_date[0] }}</span
                    >
                  </v-col>
                  <v-col cols="12" md="3" v-if="on_special_flag">
                    <v-menu
                        ref="menu"
                        no-title
                        v-model="on_special_end_date"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="product.on_special_end_date"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :error="$v.product.on_special_end_date.$error"
                            dense
                            outlined
                        >
                          <template v-slot:label>
                            Special view up to date <span class="text-danger">*</span>
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                          no-title
                          v-model="product.on_special_end_date"
                          :active-picker.sync="activePicker"
                          :min="product.on_special_start_date"
                          @change="save"
                      >
                      </v-date-picker>
                    </v-menu>
                    <div
                        class="text-danger"
                        v-if="$v.product.on_special_end_date.$error"
                    >
                      This information is required
                    </div>
                    <span class="text-danger" v-if="errors.on_special_end_date"
                    >*{{ errors.on_special_end_date[0] }}</span
                    >
                  </v-col>
                </v-row>
              </v-tab-item>
              <!--Media-->
              <v-tab-item class="p-4" v-if="productId" :value="'tab-11'">
                <Media
                    :product_id="product.id"
                ></Media>
              </v-tab-item>
            </v-tabs>

            <v-row>
              <v-col cols="12 text-right">
                <v-btn
                    :loading="loading"
                    @click.prevent="saveProduct()"
                    x-large
                    dark
                >Save</v-btn>
<!--                <v-btn class="btn btn-standard" @click="back()" dark-->
<!--                >Cancel</v-btn-->
<!--                >-->
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import GradeService from "@/services/product/grade/GradeService";
import CategoryService from "@/services/product/category/CategoryService";
import InstrumentService from "@/services/product/instrument/InstrumentService";
import SyllabusService from "@/services/product/syllabus/SyllabusService";
import ProductService from "@/services/product/ProductService";
import ExamTypeService from "@/services/product/exam-type/ExamTypeService";
import ExamOptionService from "@/services/product/exam-option/ExamOptionService";
import ScoreService from "@/services/score/score/ScoreService";
import { required, requiredIf } from "vuelidate/lib/validators";
import SettingService from "@/services/setting/SettingService";
import Media from "./widgets/Media";
import ProductMediaService from "@/services/product/product-media/ProductMediaService";
import CollaborativeInstrumentCategoryService from "@/services/product/collaborative-instrument-category/CollaborativeInstrumentCategoryService";

const collaborativeInstrumentCategory=new CollaborativeInstrumentCategoryService();
const productMediaService = new ProductMediaService();
const setting=new SettingService();
const grade = new GradeService();
const category = new CategoryService();
const product = new ProductService();
const instrument = new InstrumentService();
const syllabus = new SyllabusService();
const exam_type = new ExamTypeService();
const exam_option = new ExamOptionService();
const score = new ScoreService();

export default {
  validations: {
    product: {
      name: { required },
      short_description: {},
      long_description: {},
      candidate_welcome_message: {},
      subject_code: {required},
      default_price: {},
      on_special: { required },
      on_special_price: {
        required: requiredIf(function (nestedModel) {
          return this.product.on_special;
        }),
      },
      special_pricing_text: {
        required: requiredIf(function (nestedModel) {
          return this.product.on_special;
        }),
      },
      on_special_start_date: {
        required: requiredIf(function (nestedModel) {
          return this.product.on_special;
        }),
      },
      on_special_end_date: {
        required: requiredIf(function (nestedModel) {
          return this.product.on_special;
        }),
      },
      type: { required },
      exam_duration: {},
      category_id: {},
      instrument_id: {},
      syllabus_id: {},
      grade_id: {},
      exam_type_id: {},
      exam_option_id: {},
      requires_hand_selection: { required },
      marking_type: { required:
        requiredIf(function (nestedModel) {
          return (this.product.type != 'ameb_award'); 
        })
      },
      certificate_type: { required:
        requiredIf(function (nestedModel) {
          return (this.product.type != 'ameb_award'); 
        })
      },
      requires_playlist: { required },
      playlist_type: {
        required: requiredIf(function (nestedModel) {
          return this.product.requires_playlist;
        }),
      },
      requires_aural_sight_reading: {},
      is_group_exam: { required },
      is_group_report: { required },
      group_min_candidates: {
        required: requiredIf(function (nestedModel) {
          return this.product.is_group_exam;
        }),
      },
      group_max_candidates: {
        required: requiredIf(function (nestedModel) {
          return this.product.is_group_exam;
        }),
      },
      group_price_per_additional_candidates: {
        required: requiredIf(function (nestedModel) {
          return this.product.is_group_exam;
        }),
      },
      group_certificate_type: {
        required: requiredIf(function (nestedModel) {
          return this.product.is_group_exam;
        }),
      },
      requires_collaborative_instrument: { required },
      collaborative_instrument_category_id: {
        required: requiredIf(function (nestedModel) {
          return this.product.requires_collaborative_instrument;
        }),
      },
      external_key_official: {},
      external_key_practice: {},
      position: {},
      applicable_scores: {},
      is_enroler_active: {},
      old_table_id: {},
      old_table_name: {},
      is_active: { required },
      is_free: { required },
      no_of_contributions:{
        required: requiredIf(function (nestedModel) {
          return (this.product.type == 'ameb_award'); 
        })
      },
      no_of_performances:{
        required: requiredIf(function (nestedModel) {
          return (this.product.type == 'ameb_award'); 
        })
      },
      min_required_leadership_contributions:{
        required: requiredIf(function (nestedModel) {
          return (this.product.type == 'ameb_award'); 
        })
      },
      min_required_substantial_contributions:{
        required: requiredIf(function (nestedModel) {
          return (this.product.type == 'ameb_award'); 
        })
      },
      send_repertoire_reminder_email: {},
      no_of_day_for_reminder: {
        required: requiredIf(function (nestedModel) {
          return this.product.send_repertoire_reminder_email;
        }),
      }
    },
  },
  components: {
    Media
  },
  data() {
    return {
      attrs:[],
      toggler:false,
      currentUser: {},
      imgurl: null,
      categories: [],
      collaborative_instrument_categories: [],
      grades: [],
      instruments: [],
      exam_types: [],
      exam_options: [],
      syllabuses: [],
      scores: [],
      title: "",
      errors: [],
      products: [],
      productSearch: null,
      selectCorequisitesProduct: null,
      corequisites_products: [],
      on_special_start_date: null,
      on_special_end_date: null,
      activePicker: null,
      instrument_flag: false,
      loading: false,
      loading_instrument : false,
      loading_syllabus : false,
      syllabus_flag: false,
      exam_type_flag: false,
      exam_option_flag: false,
      group_exam_flag: false,
      on_special_flag: false,
      collaborative_instrument_flag: false,
      requires_aural_sight_reading_flag: false,
      product: {
        name: "",
        certificate_print_name: "",
        short_description: "",
        long_description: "",
        candidate_welcome_message: "",
        subject_code: "",
        default_price: null,
        on_special_price: null,
        on_special: 0,
        special_pricing_text: null,
        on_special_start_date: "",
        on_special_end_date: "",
        type: "",
        is_administrated_by_federal: false,
        exam_duration: null,
        category_id: null,
        instrument_id: null,
        syllabus_id: null,
        grade_id: null,
        exam_type_id: null,
        exam_option_id: null,
        requires_hand_selection: 0,
        marking_type: "",
        certificate_type: "",
        requires_playlist: 0,
        playlist_type: '',
        requires_aural_sight_reading: 0,
        is_group_exam: 0,
        is_group_report: 0,
        group_min_candidates: null,
        group_max_candidates: null,
        group_certificate_type: "individual",
        requires_collaborative_instrument: 0,
        collaborative_instruments: "",
        external_key_official: "",
        external_key_practice: "",
        position: null,
        applicable_scores: "",
        old_table_id: null,
        old_table_name: "",
        is_enroler_active:1,
        is_admin_active:1,
        is_active: 1,
        is_free: 0,
        practical_result_report_options:[],
        mark_types:'',
        piece_naming_standard:'',
        general_remarks_naming_standard:'',
        show_account_holder_second_page_notification: false,
        requires_guitar_selection:false,
        requires_additional_option_title:false,
        additional_option_title_value:'',
        online_exam_platform:'',
        certificate_print_criteria:'',
        has_corequisites_requirement:'',
        corequisites_product_ids: [],
        has_external_invigilator:false,
        certificate_template_type: '',
        is_pplatepiano: 0,
        is_diploma: 0,
        group_price_per_additional_candidates:null,
        no_of_contributions: null,
        no_of_performances: null,
        min_required_leadership_contributions: null,
        min_required_substantial_contributions: null,
        send_repertoire_reminder_email: 0,
        no_of_day_for_reminder: 3,
        marking_score_type: 'ameb',
        require_recorded_accompaniment: 0,
        require_teaching_grade: 0
      },
      mark_types: [],
      additional_option_title_value:[],
      practical_result_report_options:[],
      practical_result_report_value:[],
      piece_naming_standard:[],
      certificate_template_types:[
        {
          name: 'Standard',
          value: 'standard'
        },
        {
          name: 'Associate',
          value: 'associate'
        },
        {
          name: 'Licentiate',
          value: 'licentiate'
        },
        {
          name: 'Standard (with distinction)',
          value: 'standard_with_distinction'
        },
        {
          name: 'Associate (with distinction)',
          value: 'associate_with_distinction'
        },
        {
          name: 'Licentiate (with distinction)',
          value: 'licentiate_with_distinction'
        },
        {
          name: 'P-Plate Piano',
          value: 'p_plate_piano'
        },
      ],
      managedBy:[
        {
          name: 'Federal',
          value: 'federal'
        },
        {
          name: 'Score',
          value: 'score'
        },
        {
          name: 'Both',
          value: 'both'
        },
      ],
      shortDescriptionEditorConfig: {
        toolbar: [
          [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "RemoveFormat",
            "Link",
            "NumberedList",
            "BulletedList",
          ],
        ],
        versionCheck: false
      },

      longDescriptionEditorConfig: {
        toolbar: [
          [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "RemoveFormat",
            "Link",
            "NumberedList",
            "BulletedList",
          ],
        ],
        versionCheck: false
      },

      candidateWelcomeMessageEditorConfig: {
        toolbar: [
          [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "RemoveFormat",
            "Link",
            "NumberedList",
            "BulletedList",
          ],
        ],
        versionCheck: false
      },

      types: [
        {
          text: "Online Theory Exam",
          value: "online_exam",
        },
        {
          text: "Online Theory Course ",
          value: "online_course",
        },
        {
          text: "Practical Exam",
          value: "practical_exam",
        },
        {
          text: "AMEB Award",
          value: "ameb_award",
        },
      ],
      certificate_types: [
        {
          text: "Physical",
          value: "physical",
        },
        {
          text: "Digital",
          value: "digital",
        },
        {
          text: "Mixed",
          value: "mixed",
        },
      ],
      marking_types: [
        {
          text: "Automated",
          value: "automated",
        },
        {
          text: "Manual",
          value: "manual",
        },
        {
          text: "Mixed",
          value: "mixed",
        },
      ],
      group_certificate_types: [
        {
          text: "Individual",
          value: "individual",
        },
        {
          text: "Group",
          value: "group",
        },
        {
          text: "Detail",
          value: "detail",
        },
      ],
      playlist_types: [
        {
          name: "File",
          value: "file",
        },
        {
          name: "Form",
          value: "form",
        },
      ],
      platforms:[
        {value:'web',text:'Web version'},
        {value:'desktop',text:'Desktop software'}
      ],
      markingScoreTypes:[
        {
          name: 'AMEB',
          value: 'ameb'
        },
        {
          name: 'Rockschool',
          value: 'rockschool'
        },
      ],
      playlist_type_flag: false,
      software_external_keys_flag: false,
      practical_exam_fields_flag: false,
      ameb_award_fields_flag: false,
      currentDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      categoryDetail:null,
      instrumentDetail:null,
      syllabusDetail:null,
      examTypeDetail:null,
      mark_options:[],
      searchData: {
        name: '',
        type: ['practical_exam', 'online_exam']
      },
      product_tab:'tab-1',
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    productSearch (val) {
      if(val) {
        this.isLoading = true;
        this.searchData.name = val;
        product
            .searchByType(this.searchData)
            .then((response) => {
              this.products = response.data.products;
            })
            .catch(() => {

            }).finally(() => (this.isLoading = false))
      }
    }
  },
  computed: {
    productId() {
      return this.$route.params.productId;
    },
    allSelectType () {
      return this.product.applicable_scores.length === this.scores.length
    },
    selectType () {
      return this.product.applicable_scores.length > 0 && !this.allSelectType
    },
    icon () {
      if (this.allSelectType) return 'mdi-close-box'
      if (this.selectType) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    allSelectAcceptedValue () {
      return this.practical_result_report_value.length === this.practical_result_report_options.length;
    },
    selectAcceptValue () {
      return this.practical_result_report_value.length > 0 && !this.allSelectAcceptedValue;
    },
    iconAcceptValue () {
      if (this.allSelectAcceptedValue) return 'mdi-close-box'
      if (this.selectAcceptValue) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods: {
    back() {
      this.$tabs.close();

    },
    goToValidationStep(){
      if(this.$v.$error) {
        if (this.$v.product.type.$error || this.$v.product.name.$error || this.$v.product.subject_code.$error || this.$v.product.exam_duration.$error || this.$v.product.applicable_scores.$error || this.$v.product.long_description.$error || this.$v.product.short_description.$error) {
          this.product_tab = 'tab-1';
        } else if (this.$v.product.category_id.$error || this.$v.product.instrument_id.$error || this.$v.product.syllabus_id.$error || this.$v.product.grade_id.$error || this.$v.product.exam_type_id.$error || this.$v.product.exam_option_id.$error) {
          this.product_tab = 'tab-2';
        } else if (this.$v.product.marking_type.$error || this.$v.product.certificate_type.$error) {
          this.product_tab = 'tab-3';
        } else if (this.$v.product.is_group_exam.$error || this.$v.product.is_group_report.$error || this.$v.product.group_min_candidates.$error || this.$v.product.group_max_candidates.$error || this.$v.product.group_certificate_type.$error || this.$v.product.group_price_per_additional_candidates.$error) {
          this.product_tab = 'tab-4';
        }else if(this.$v.product.playlist_type.$error || this.$v.product.requires_aural_sight_reading.$error){
          this.product_tab = 'tab-6';
        }else if(this.$v.product.requires_collaborative_instrument.$error || this.$v.product.collaborative_instrument_category_id.$error){
          this.product_tab = 'tab-7';
        }else if(this.$v.product.default_price.$error || this.$v.product.on_special_price.$error || this.$v.product.special_pricing_text.$error || this.$v.product.on_special_start_date.$error || this.$v.product.on_special_end_date.$error){
          this.product_tab = 'tab-10';
        }
      }
    },
    saveProduct() {
      this.$v.$touch();
      if (this.$v.$error) {
        this.goToValidationStep();
        this.$snotify.error("Required field missing");
        setTimeout(() => {
          this.$v.$reset();
        }, 20000);
      } else {
        this.loading = true;

        if(this.practical_result_report_value.length >0){
          this.product.practical_result_report_options=this.practical_result_report_value.toString();
        }else{
          this.product.practical_result_report_options=null;
        }

        if(this.product.mark_types=='grade' || this.product.mark_types=='title' || this.product.mark_types=='rockschool_professional_diplomas'){
          if(this.product.certificate_print_criteria){
            this.product.certificate_print_criteria=this.product.certificate_print_criteria.toString();
          }

        }

        if (this.productId == null || this.productId == undefined) {
          product
              .create(this.product)
              .then((response) => {
                this.$snotify.success("Product added");
                this.resetForm();
                this.loading = false;
                this.$router.push({
                  name: "product",
                });
              })
              .catch((err) => {
                this.loading = false;
                this.errors = err.errors;
                this.$snotify.error("Oops something went wrong");
              });
        } else {
          this.loading = true;
          product
              .update(this.productId, this.product)
              .then((response) => {
                this.$snotify.success("Product updated");
                this.resetForm();
                this.loading = false;
                this.productDetail();
              })
              .catch((err) => {
                this.loading = false;
                this.errors = err.errors;
                this.$snotify.error("Oops something went wrong");
              });
        }
      }
    },
    resetForm() {
      this.product = {
        name: "",
        certificate_print_name: "",
        short_description: "",
        long_description: "",
        subject_code: "",
        default_price: null,
        on_special_price: null,
        on_special: 0,
        special_pricing_text: null,
        on_special_start_date: "",
        on_special_end_date: "",
        type: "",
        exam_duration: null,
        category_id: null,
        instrument_id: null,
        syllabus_id: null,
        grade_id: null,
        exam_type_id: null,
        exam_option_id: null,
        requires_hand_selection: 0,
        marking_type: "",
        certificate_type: "",
        requires_playlist: 0,
        playlist_type: '',
        requires_aural_sight_reading: 0,
        is_group_exam: 0,
        is_group_report: 0,
        group_min_candidates: null,
        group_max_candidates: null,
        group_certificate_type: "",
        requires_collaborative_instrument: 0,
        collaborative_instruments: "",
        external_key_official: "",
        external_key_practice: "",
        position: null,
        applicable_scores: "",
        old_table_id: null,
        old_table_name: "",
        is_active: 0,
        is_free: 0,
        practical_result_report_options: [],
        mark_types: '',
        piece_naming_standard:'',
        general_remarks_naming_standard:'',
        is_admin_active:true,
        show_account_holder_second_page_notification: false,
        certificate_template_type: '',
        is_pplatepiano: 0,
        is_diploma: 0,
        is_administrated_by_federal: false,
        no_of_contributions: null,
        no_of_performances: null,
        send_repertoire_reminder_email: 0,
        no_of_day_for_reminder: 3
      };
      this.errors = [];
      this.$v.$reset();
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    getAllGrades() {
      grade
          .all()
          .then((response) => {
            this.grades = response.data.grades;
          })
          .catch((err) => {});
    },
    getAllCategories() {
      category
          .all()
          .then((response) => {
            this.categories = response.data.categories;
          })
          .catch((err) => {});
    },
    productDetail() {
      if (this.productId != null) {
        this.title = "Edit";
        this.showProduct();
      } else {
        this.title = "Add";
      }
    },
    allSelectScores () {
      this.$nextTick(() => {
        if (this.allSelectType) {
          this.product.applicable_scores = []
        } else {
          this.product.applicable_scores = [];
          this.scores.forEach(score => {
            this.product.applicable_scores.push(score.id);
          });
        }
      })
    },
    showProduct() {
      this.loading =true;
      product
          .show(this.productId)
          .then((response) => {
            this.product = response.data.product;
            if(this.product.category_id){
              this.changeCategory();
            }

            if (this.product.on_special) {
              this.on_special_flag = true;
            }
            if (this.product.is_group_exam) {
              this.group_exam_flag = true;
            }
            if (this.product.requires_playlist) {
              this.requires_aural_sight_reading_flag = true;
              this.playlist_type_flag = true;
            }
            if (this.product.requires_collaborative_instrument) {
              this.requires_aural_sight_reading_flag = true;
            }
            if (this.product.requires_collaborative_instrument) {
              this.collaborative_instrument_flag = true;
            }
            if(this.product.applicable_scores == null) {
              this.product.applicable_scores = [];
            }
            if(this.product.practical_result_report_options!=null){
              this.practical_result_report_value=this.product.practical_result_report_options.split(',');
            }
            this.changeMarkType();

            if(this.product.certificate_print_criteria){
              this.product.certificate_print_criteria=this.product.certificate_print_criteria.split(',')
            }
            if(this.product.corequisites_product_ids == null){
              this.product.corequisites_product_ids = [];
              this.corequisites_products = [];
            }else {
              this.corequisites_products = this.product.corequisites_products;
            }
            this.handleProductTypeChange();
          })
          .catch((err) => {})
          .finally(() => {
            this.loading =false;
          });
    },

    setCorequisitesProduct() {
       if(this.selectCorequisitesProduct) {
         this.product.corequisites_product_ids.push(this.selectCorequisitesProduct.id)
         this.corequisites_products.push({name: this.selectCorequisitesProduct.name})
       }
        this.selectCorequisitesProduct = null;
        this.productSearch = null;
        this.products = [];
    },
    removeCorequisitesProduct(index) {
      this.product.corequisites_product_ids.splice(index,1)
      this.corequisites_products.splice(index, 1)
    },

    handleGroupExam() {
      this.group_exam_flag = !this.group_exam_flag;
      if (this.title != "Edit") {
        this.product.is_group_report = 0;
        this.product.group_min_candidates = null;
        this.product.group_max_candidates = null;
        this.product.group_certificate_type = "";
        this.product.group_price_per_additional_candidates = null;
      }
    },
    handleOnSpecial() {
      this.on_special_flag = !this.on_special_flag;
      if (this.title != "Edit") {
        this.product.on_special_price = null;
        this.product.special_pricing_text = null;
        this.product.on_special_start_date = null;
        this.product.on_special_end_date = null;
      }
    },
    handleRequireCollaborativeInstrument() {
      this.collaborative_instrument_flag = !this.collaborative_instrument_flag;
      if (this.title != "Edit") {
        this.product.collaborative_instruments = "";
      }
    },
    handleRequirePlaylist() {
      this.playlist_type_flag = !this.playlist_type_flag;
      this.requires_aural_sight_reading_flag =
          !this.requires_aural_sight_reading_flag;
      if (this.title != "Edit") {
        this.product.requires_aural_sight_reading = 0;
      }
    },
    handleProductTypeChange(){
      if(this.product.type == 'online_course' || this.product.type == 'online_exam'){
        this.software_external_keys_flag = true;
        this.practical_exam_fields_flag = false;
      }
      if(this.product.type == 'practical_exam'){
        this.software_external_keys_flag = false;
        this.practical_exam_fields_flag = true;
        this.getAdditionalOptionTitleValue();
      }
      if(this.product.type == 'ameb_award'){
        this.software_external_keys_flag = false;
        this.ameb_award_fields_flag = true;
      }
    },
    getAllScores() {
      score
          .all()
          .then((response) => {
            this.scores = response.data.scores;
          })
          .catch((err) => {});
    },
    getAcceptedValueData(){
      setting
          .getAcceptedValueData()
          .then(response => {
            for(let key in response.data.practical_result_report_options){
              this.practical_result_report_options.push({value:key, text:response.data.practical_result_report_options[key]})
            }
          })
    },
    getMarkedTypeOption(){
      setting
          .getMarkedTypeOption()
          .then(response => {
            for(let key in response.data.marked_type_options){
              this.mark_types.push({value:key, text:response.data.marked_type_options[key]})
            }
          })
    },
    getMarkedOption(){
      this.mark_options=[];
      setting
          .getMarkOptions({'type': this.product.mark_types})
          .then(response => {
            for(let key in response.data.mark_options){
              this.mark_options.push({value:key, text:response.data.mark_options[key]})
            }
          })
    },
    changeMarkType(){

      if(this.product.mark_types=='grade' || this.product.mark_types=='title' || this.product.mark_types=='rockschool_professional_diplomas'){
        this.getMarkedOption();
      }
    },
    allAcceptedValue() {
      this.$nextTick(() => {
        if (this.allSelectAcceptedValue) {
          this.practical_result_report_value = []
        } else {
          this.practical_result_report_value = [];
          this.practical_result_report_options.forEach(option => {
            this.practical_result_report_value.push(option.value);
          });
        }
      })
    },
    getPieceNamingOption(){
      setting
          .getPieceNamingOption()
          .then(response => {
            for(let key in response.data.piece_naming_options){
              this.piece_naming_standard.push({value:key, text:response.data.piece_naming_options[key]})
            }
          });
    },
    //Category
    changeCategory(){
      this.getCategoryDetail();
    },
    getCategoryDetail(){
      if(this.product.category_id){
        category
            .show(this.product.category_id)
            .then(response => {
              this.categoryDetail=response.data.category;
              if(this.categoryDetail && this.categoryDetail.has_instruments){
                this.instrument_flag=true;
                this.getInstruments();
                if(this.product.instrument_id){
                  this.getInstrumentDetail();
                }
              }else{
                this.instrument_flag=false;
              }
              if(this.categoryDetail && !this.categoryDetail.has_instruments && this.categoryDetail.has_syllabuses){

                this.syllabus_flag=true;
                this.getSyllabuses(false);
                if(this.product.syllabus_id){
                  this.getSyllabusDetail();
                }
              }else{
                this.syllabus_flag=false
              }
              if(this.categoryDetail && !this.categoryDetail.has_instruments && !this.categoryDetail.has_syllabuses && this.categoryDetail.has_exam_types){
                this.exam_type_flag=true;
                this.getAllExamTypes(false,false);
                if(this.product.exam_type_id){
                  this.getExamTypeDetail();
                }
              }else{
                this.exam_type_flag=false
              }
              if(this.categoryDetail && !this.categoryDetail.has_instruments && !this.categoryDetail.has_syllabuses && !this.categoryDetail.has_exam_types && this.categoryDetail.has_exam_options){
                this.exam_option_flag=true;
                this.getAllExamOptions(false,false,false);
              }else{
                this.exam_option_flag=false;
              }
            })
            .catch((err) => {
              this.$snotify.error("Oops something went wrong");
            });
      }

    },
    //Instrument
    getInstruments() {
      let data={};
      if(this.product.category_id){
        data['category_id']=this.product.category_id;
      }
      instrument
          .getFilterData(data)
          .then(response => {
            this.instruments=response.data.instruments
          })
          .catch((err) => {
          });
    },
    changeInstrument(){
      this.getInstrumentDetail();
    },
    getInstrumentDetail(){
      if(this.product.category_id && this.product.instrument_id){
        instrument
            .show(this.product.instrument_id)
            .then(response => {
              this.instrumentDetail=response.data.instrument;

              if(this.instrumentDetail && this.instrumentDetail.has_syllabuses){
                this.syllabus_flag=true;
                this.getSyllabuses(true);
                if(this.product.syllabus_id){
                  this.getSyllabusDetail();
                }
              }else{
                this.syllabus_flag=false;
              }
              if(this.instrumentDetail && !this.instrumentDetail.has_syllabuses && this.instrumentDetail.has_exam_types){
                this.exam_type_flag=true;
                this.getAllExamTypes(true,false);
                if(this.product.exam_type_id){
                  this.getExamTypeDetail();
                }
              }else{
                this.exam_type_flag=false;
              }
              if(this.categoryDetail && this.categoryDetail.has_exam_options){
                if(this.instrumentDetail && !this.instrumentDetail.has_syllabuses && !this.instrumentDetail.has_exam_types && this.instrumentDetail.has_exam_options){
                  this.exam_option_flag=true;
                  this.getAllExamOptions(true,false,false);
                }else{
                  this.exam_option_flag=false;
                }
              }

            })
            .catch((err) => {
              this.$snotify.error("Oops something went wrong");
            });
      }

    },
    //Syllabus
    getSyllabuses(hasInstrument){
      let data={};
      if(this.product.category_id){
        data['category_id']=this.product.category_id;
      }
      if(hasInstrument && this.product.instrument_id){
        data['instrument_id']=this.product.instrument_id;
      }
      syllabus
          .getFilterData(data)
          .then(response => {
            this.syllabuses=response.data.syllabuses;
          })
          .catch((err) => {

          });
    },
    changeSyllabus(){
      this.getSyllabusDetail()
    },
    getSyllabusDetail(){
      if(this.product.syllabus_id){
        syllabus
            .show(this.product.syllabus_id)
            .then(response => {
              this.syllabusDetail=response.data.syllabus;

              if(this.syllabusDetail && this.syllabusDetail.has_exam_types){
                this.exam_type_flag=true;
                this.getAllExamTypes(true,true);
                if(this.product.exam_type_id){
                  this.getExamTypeDetail();
                }
              }else{
                this.exam_type_flag=false;
              }
              if(this.categoryDetail && this.categoryDetail.has_exam_options) {
                if (this.syllabusDetail && !this.syllabusDetail.has_exam_types && this.syllabusDetail.has_exam_options) {
                  this.exam_option_flag = true;
                  this.getAllExamOptions(true, true, false);
                } else {
                  this.exam_option_flag = false;
                }
              }
            })
            .catch((err) => {
              this.$snotify.error("Oops something went wrong");
            });
      }

    },
    //ExamType
    getAllExamTypes(hasInstrument,hasSyllabus) {
      let data={};
      if(this.product.category_id){
        data['category_id']=this.product.category_id;
      }
      if(hasInstrument && this.product.instrument_id){
        data['instrument_id']=this.product.instrument_id;
      }
      if(hasSyllabus && this.product.syllabus_id){
        data['syllabus_id']=this.product.syllabus_id;
      }
      if(this.product.grade_id){
        data['grade_id']=this.product.grade_id;
      }
      exam_type
          .getAllExamType(data)
          .then(response => {
            this.exam_types = response.data.exam_types;
          })
          .catch((err) => {
          });
    },
    changeExamType(){
      this.getExamTypeDetail();
    },
    getExamTypeDetail(){
      if(this.product.exam_type_id){
        exam_type
            .show(this.product.exam_type_id)
            .then(response => {
              this.examTypeDetail=response.data.exam_type;
              if(this.examTypeDetail && this.examTypeDetail.has_exam_options){
                this.exam_option_flag=true;
                this.getAllExamOptions(true,true,true);
              }else{
                this.exam_option_flag=false;
              }

            })
            .catch((err) => {
              this.$snotify.error("Oops something went wrong");
            });
      }

    },
    //Exam Option
    getAllExamOptions(hasInstrument,hasSyllabus,hasExamType) {
      let data={};
      if(this.product.category_id){
        data['category_id']=this.product.category_id;
      }
      if(hasInstrument && this.product.instrument_id){
        data['instrument_id']=this.product.instrument_id;
      }
      if(hasSyllabus && this.product.syllabus_id){
        data['syllabus_id']=this.product.syllabus_id;
      }
      if(hasExamType && this.product.exam_type_id){
        data['exam_type_id']=this.product.exam_type_id;
      }
      if(this.product.grade_id){
        data['grade_id']=this.product.grade_id;
      }
      exam_option
          .getAllExamOption(data)
          .then((response) => {
            this.exam_options = response.data.exam_options;
          })
          .catch((err) => {

          });
    },
    getAdditionalOptionTitleValue(){
        setting
            .getAdditionalOptionTitleValue()
            .then(response => {
              for(let key in response.data.additional_option_title_value){
                this.additional_option_title_value.push({value:key, text:response.data.additional_option_title_value[key]})
              }
            })
            .catch((err) => {
              this.$snotify.error("Oops something went wrong");
            });
    },
    getAllCollaborativeInstrumentCategories(){
      collaborativeInstrumentCategory
          .getActiveData()
          .then(response => {
            this.collaborative_instrument_categories=response.data.categories;
          })
          .catch((err) => {

          })
          .finally(() => {

          });
    },
  },
  mounted() {
    this.currentUser = this.$store.getters.currentUser;
    this.getAllGrades();
    this.getAllScores();
    this.getAllCategories();


    this.productDetail();
    this.getAcceptedValueData();
    this.getMarkedTypeOption();
    this.getPieceNamingOption();
    this.getAllCollaborativeInstrumentCategories();
  },
};
</script>

<style>
#fixedButton
{
  position: fixed;
  left: 85%;
  top: 82%;
  opacity: 0.3;
}
#fixedButton:hover {
  opacity: 1.0;
}
</style>