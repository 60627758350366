<template>
  <v-app>
    <v-overlay :value="overlay">
        <v-progress-circular
            :size="50"
            color="white"
            indeterminate
        ></v-progress-circular>
    </v-overlay>

    <v-card
        outlined
        class="mb-2"
    >
      <v-card-title class="mt-2 d-flex justify-end">
        <div v-if="productMediasData.length > 0">
            <v-btn 
              v-if="add"
              :loading="loading" 
              @click="saveMedia" 
              class="mx-2 btn btn-primary text-white" 
              small 
              elevation="3" 
              type="submit"
            >
              <i class="fa fa-save"></i>
              Save
            </v-btn>
            <v-btn
                v-if="! add"
                @click="add=true;"
                small
                class="btn btn-primary text-white mx-2" 
            >
                <v-icon small  elevation="2" outlined>fas fa-plus</v-icon>
                &nbsp;Add Image
            </v-btn>  
        </div>
        <v-btn 
          class="btn btn-sm btn-standard mx-2" 
          small 
          @click="cancelFormAction" 
          elevation="3" 
          outlined
          v-if="add && productMediasData.length > 0"
        >
          Cancel
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-col col="12" v-if="add && productMediasData.length > 0">
          <v-file-input
              outlined
              dense
              v-model="productMedia.file"
              placeholder="Product Image"
              label="Product Image"
              multiple
              prepend-icon="mdi-paperclip">
          </v-file-input>
      </v-col>

      <div v-if="productMediasData.length > 0" class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <td></td>
              <td></td>
              <td>Options</td>
              <td>Drag to sort</td>                      
            </tr>
          </thead>
          <template>
            <draggable 
              class="w-100" 
              v-model="productMediasData" 
              @change="sort" 
              @start="drag=true" 
              @end="drag=false"
              tag="tbody"
            >
              <tr v-for="(item, index) in productMediasData">
                <td>
                  <b-img
                    @click="openGallery(index)"
                    style="height: 7rem; width:auto; cursor:pointer; object-fit:cover;"
                    fluid 
                    class="border rounded img-thumbnail"
                    :src="item.image_path.thumb"
                  >
                  </b-img>
                </td>
                <td class="py-10">
                  <div v-if="item.is_featured" class="btn btn-sm btn-success">
                      <i class="fa fa-check-circle text-white"></i>
                      Featured Image
                  </div>
                  <div v-if="item.is_banner" class="btn btn-sm btn-success">
                      <i class="fa fa-check-circle text-white"></i>
                      Banner Image
                  </div>
                  <div class="font-size-lg mt-2">
                    <i class="fa fa-calendar"></i> {{item.upload_date}}
                  </div>
                </td>
                <td class="py-10">
                  <v-btn
                    @click="deleteMedia(item.id)"
                    elevation="3"
                    color="#dc3545"
                    small
                    class="ml-3"
                  >
                    <v-icon color="#fff" class="font-13">
                        mdi-delete
                    </v-icon> &nbsp; <span class="text-white"> Delete</span>
                  </v-btn>
                  <v-btn
                    v-if="!item.is_featured"
                    class="ml-3 btn btn-primary "
                    @click="setFeaturedImage(item.id)"
                    elevation="3"
                    small
                  >
                      <v-icon color="#fff" class="font-13">
                          mdi-image
                      </v-icon> &nbsp; <span class="text-white">Set as featured image</span>
                  </v-btn>
                  <v-btn
                    v-if="!item.is_banner"
                    class="ml-3 btn btn-primary "
                    @click="setBannerImage(item.id)"
                    elevation="3"
                    small
                  >
                      <v-icon color="#fff" class="font-13">
                          mdi-image
                      </v-icon><span class="text-white">Set as Banner image</span>
                  </v-btn>
                </td>
                <td class="py-10">
                  <i class="fa fa-sort"></i>
                </td>
              </tr>
            </draggable>
          </template>
        </table>
      </div>
  
      <light-box
          v-if="images.length > 0"
          ref="lightbox"
          :media="images"
          :showLightBox="false"
      ></light-box>
              
      <v-row v-if="productMediasData.length == 0">
          <v-col v-if="! add" cols="12" class="my-5 text-center">
              <div class="mt-1 text-center font-13 text-body">
              No Image Found
              </div>
              <v-btn class="mx-2 btn btn-primary mt-2 text-white" elevation="3" small @click="add=true;">
              <v-icon color="#fff">
                  mdi-plus
              </v-icon>
              Add
              </v-btn>
          </v-col>

          <v-col class="my-5" v-if="add" cols="12">
            <div class="form-group p-4">
              <v-file-input 
                  outlined 
                  dense
                  v-model="productMedia.file"
                  placeholder="Product Image"  
                  label="Product Image" 
                  multiple
                  :error="$v.productMedia.file.$error"
                  prepend-icon=""
                  prepend-inner-icon="mdi-file"
              >
              </v-file-input> 
              <span class="text-danger" v-if="$v.productMedia.file.$error">This information is required</span>

              <v-col cols="12" class="text-center">
                  <v-btn 
                    class="mx-2 mt-4 btn btn-standard" 
                    v-if="add" 
                    small 
                    @click="cancelFormAction" 
                    elevation="3" 
                  >
                    Cancel
                  </v-btn>
                  <v-btn 
                    :loading="loading" 
                    @click="saveMedia" 
                    class="mx-2 mt-4 btn btn-primary text-white" 
                    small 
                    elevation="3" 
                    type="submit"
                  >
                    <i class="fa fa-save"></i>
                    Save
                  </v-btn>
              </v-col>
            </div>
          </v-col>   
      </v-row>

      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
import ProductMediaService from "@/services/product/product-media/ProductMediaService";
import draggable from 'vuedraggable';
import {required} from "vuelidate/lib/validators";

const productMediaService = new ProductMediaService();

export default {
  validations:{
    productMedia:{
      file: {required},
    }
  },
  components:{
    draggable
  },
  props:['product_id'],
  data(){
    return {
        edit: false,
        add:false,
        images: [],
        productMedia:{
            file: null,
            product_id: null,
        },
        imgurl: null,
        loading: false,
        overlay: false,
        toggler:false,
        productMediasData:[],
    }
  },
  computed: {
    productId() {
      return this.$route.params.productId;
    },
  },
  mounted() {
    this.getProductMedias();
  },
  methods:{
    convertToFormData(){
      let formData = new FormData();
      for (let key in this.productMedia) {
        if (key === "file" && this.productMedia[key] != null && this.productMedia[key] != undefined) {
          for(let image in this.productMedia[key]){
            formData.append('file[]', this.productMedia[key][image]);
          }
        }
        else {
          if(this.productMedia[key]){
            formData.append(key,this.productMedia[key]);
          }
        } 
      }
     
      return formData;
    },
    cancelFormAction(){
      this.edit = false;
      this.add = false;
      this.errors = [];
      this.$emit("product-image-added");
    },
    openGallery(index) {
      this.$refs["lightbox"].showImage(index);
    },
    setFeaturedImage(productMediaId){
        this.overlay = true;
        productMediaService
        .setAsFeaturedImage(productMediaId)
        .then((response) => {
            this.getProductMedias();
            this.$snotify.success("Feature image set successfully");
            this.add = false;
            this.edit = false;
            this.productMedia = {};
            this.overlay = false;
        })
        .catch((error) => {
            this.$snotify.error("Oops something went wrong!!");
            this.overlay = false;
        });
    },
    setBannerImage(productMediaId){
        this.overlay = true;
        productMediaService
        .setAsBannerImage(productMediaId)
        .then((response) => {
            this.getProductMedias();
            this.$snotify.success("Banner Image Set Successfully");
            this.add = false;
            this.edit = false;
            this.productMedia = {};
            this.overlay = false;
        })
        .catch((error) => {
            this.$snotify.error("Oops something went wrong!!");
            this.overlay = false;
        });
    },
    saveMedia(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.productMedia.product_id = this.product_id;
        let formData = this.convertToFormData();
        this.loading = true;
        productMediaService
        .create(formData)
        .then((response) => {
          this.getProductMedias();
          this.$snotify.success("Image Added Successfully");
          this.add = false;
          this.edit = false;
          this.productMedia = {};
          this.loading = false;
        })
        .catch((error) => {
          this.$snotify.error("Oops something went wrong!!");
          this.loading = false;
        });
      }
    },
    deleteMedia(mediaId){
      this.$confirm({
        message: `Are you sure? `,
        button: {
            no: "No",
            yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            this.overlay = true;
            productMediaService
            .delete(mediaId)
            .then((response) => {
              this.$snotify.success('Image Deleted Successfully');
              this.getProductMedias();
              this.overlay = false;
            })
            .catch((error) => {
              this.overlay = false;
              this.$snotify.error('Oops something went wrong !!');
            })
          }
        }
      });
    },
    getProductMedias(){
      productMediaService
      .getAllMediasByProductId(this.productId)
      .then((response) => {
        this.productMediasData = response.data.productMedias;
        this.images = [];
        this.productMediasData.forEach(image => {
          this.images.push({
            id: image.name,
            thumb: image.image_path.thumb,
            src: image.image_path.original
          });
        });
      })
      .catch((err) => {
      })
    },
    sort(){
      this.overlay = true;
      productMediaService
      .sort(this.productId, this.productMediasData)
      .then(response => {
        this.getProductMedias();
        this.$snotify.success('Product medias sorted succesfully.');
        this.overlay = false;
      })
      .catch(err => {
        this.$snotify.error('Oops medias could not be sorted.')
        this.overlay = false;
      });
    }
  }
}
</script>

<style scoped>
  .font-13{
    font-size: 13px;
  }
  .font-weight-600{
    font-weight: 600;
  }
</style>