import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

    export default class ProductMediaService
    {
        #api = null;
        constructor() {
            this.#api = API_URL + 'admin/product-media';
        }
        getAllMediasByProductId(productId) {
            let url = `${this.#api}/${productId}/all`;
            return apiService.get(url)
        }
        
        create(data) {
            let url = `${this.#api}`
            return apiService.post(url, data)
        }

        update(id,data) {
            let url = `${this.#api}/${id}`
            return apiService.put(url,data)
        }

        setAsFeaturedImage(id) {
            let url = `${this.#api}/${id}/set-featured-image`
            return apiService.post(url)
        }

        setAsBannerImage(id) {
            let url = `${this.#api}/${id}/set-banner-image`
            return apiService.post(url)
        }
        
        delete(productMediaId) {
            let url = `${this.#api}/${productMediaId}`
            return apiService.delete(url)
        }

        sort(productId, data=[]){
            let url = `${this.#api}/${productId}/sort`;
            return apiService.post(url, data);
        }
    }
